// src/components/Chat/MessageList.js
import React, { useEffect, useRef } from "react";
import Message from "./Message";
import DateBubble from "./DateBubble";
import moment from "moment";
import PropTypes from "prop-types";

function MessageList({ messages }) {
  const messageEndRef = useRef(null);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Ordenar los mensajes en orden ascendente por timestamp
  const sortedMessages = [...messages].sort((a, b) => {
    return moment(a.timestamp).diff(moment(b.timestamp));
  });

  // Agrupar mensajes por fecha
  const groupedMessages = sortedMessages.reduce((acc, msg) => {
    const date = moment(msg.timestamp).format("DD-MM-YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(msg);
    return acc;
  }, {});

  // Obtener las fechas ordenadas en orden ascendente
  const sortedDates = Object.keys(groupedMessages).sort((a, b) => {
    return moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"));
  });

  return (
    <div className="flex flex-col p-4 space-y-2 overflow-y-auto">
      {sortedDates.map((date, index) => (
        <div key={index}>
          <DateBubble date={date} />
          {groupedMessages[date].map((msg, msgIndex) => (
            <Message
              key={msgIndex}
              sender={msg.sender}
              message={msg.message}
              timestamp={msg.timestamp}
            />
          ))}
        </div>
      ))}
      <div ref={messageEndRef} />
    </div>
  );
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      sender: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MessageList;
