// src/components/Chat/ChatList.js
import React from "react";
import PropTypes from "prop-types"; // Importar PropTypes para validación de props
import SearchChat from "./SearchChat";
import logo from "../../assets/images/LogoBBUKZblanco.png";
import {
  FaUsers,
  FaChartBar,
  FaInbox,
  FaCog,
  FaBullhorn,
} from "react-icons/fa"; // Importar los iconos necesarios

function ChatList({
  chats,
  onChatSelect,
  selectedChat,
  onOpenMetrics,
  onShowClientStatus,
  onOpenSuggestions,
  onOpenSettings,
  onOpenMarketing, // Nuevo handler para abrir la interfaz de marketing
  marketing, // Nueva prop para controlar la visibilidad del botón de Marketing
}) {
  const hasDeactivatedClients = chats.some((chat) => !chat.activo);

  // Ordenar los chats por el mensaje más reciente
  const sortedChats = [...chats].sort((a, b) => {
    const aLastMessageTime = a.messages.length
      ? new Date(a.messages[a.messages.length - 1].timestamp).getTime()
      : 0;
    const bLastMessageTime = b.messages.length
      ? new Date(b.messages[b.messages.length - 1].timestamp).getTime()
      : 0;
    return bLastMessageTime - aLastMessageTime;
  });

  return (
    <div className="flex flex-col w-full h-full bg-gray-200 border-r border-gray-300">
      {/* **Encabezado con Logo** */}
      <div className="flex justify-center items-center h-24 w-full bg-bluePalette-light">
        <img src={logo} alt="Logo" className="h-20 w-5/6" />
      </div>

      {/* **Sección de Botones y Búsqueda** */}
      <div className="p-2 border-b border-gray-300 bg-white">
        {/* **Botón de Métricas** */}
        <button
          className="bg-bluePalette-dark text-white p-2 rounded w-full mb-2 flex items-center justify-center"
          onClick={onOpenMetrics}
        >
          <FaChartBar className="mr-2" />
          Métricas
        </button>

        {/* **Botón de Lista de Usuarios con Indicador de Clientes Desactivados** */}
        <div className="relative pb-3">
          <button
            className="bg-bluePalette-dark text-white p-2 rounded w-full flex items-center justify-center"
            onClick={onShowClientStatus}
          >
            <FaUsers className="mr-2" />
            Lista de Usuarios
          </button>
          {hasDeactivatedClients && (
            <div className="absolute top-2 right-0 h-6 w-6 bg-orange-500 rounded-full flex items-center justify-center">
              <span className="tooltip relative">
                ⚠
                <span className="tooltiptext absolute bottom-full mb-2 right-1/2 transform translate-x-1/2 bg-gray-700 text-white text-xs rounded py-1 px-2 whitespace-nowrap opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  El chatbot está desactivado para al menos un cliente.
                </span>
              </span>
            </div>
          )}
        </div>

        {/* **Botón de Marketing (Renderizado Condicional)** */}
        {marketing && (
          <button
            className="bg-bluePalette-dark text-white p-2 rounded w-full mb-2 flex items-center justify-center"
            onClick={onOpenMarketing}
          >
            <FaBullhorn className="mr-2" />
            Marketing
          </button>
        )}

        {/* **Botones de Sugerencias y Ajustes** */}
        <div className="flex space-x-2 mb-3">
          <button
            className="bg-bluePalette-dark text-white p-2 rounded flex-1 flex items-center justify-center"
            onClick={onOpenSuggestions}
          >
            <FaInbox className="mr-2" />
            Sugerencias
          </button>
          <button
            className="bg-bluePalette-dark text-white p-2 rounded flex-1 flex items-center justify-center"
            onClick={onOpenSettings}
          >
            <FaCog className="mr-2" />
            Ajustes
          </button>
        </div>

        {/* **Componente de Búsqueda de Chats** */}
        <SearchChat chats={chats} onChatSelect={onChatSelect} />
      </div>

      {/* **Lista de Chats Ordenados** */}
      <div className="overflow-y-auto flex-grow bg-white">
        {sortedChats.map((chat) => (
          <div
            key={chat.cliente_tfn}
            onClick={() => onChatSelect(chat)}
            className={`p-4 text-lg cursor-pointer transition ease-in-out ${
              selectedChat?.thread_id === chat.thread_id
                ? "bg-bluePalette-dark text-white"
                : "bg-white hover:bg-gray-100"
            }`}
          >
            <div className="font-bold truncate">
              {chat.nombre || "Cliente sin nombre"}
            </div>
            <div className="text-sm truncate">
              {chat.cliente_tfn_sin_prefijo}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

// **Definición de PropTypes para Validación de Props**
ChatList.propTypes = {
  chats: PropTypes.arrayOf(
    PropTypes.shape({
      thread_id: PropTypes.string.isRequired,
      cliente_tfn: PropTypes.string.isRequired,
      cliente_tfn_sin_prefijo: PropTypes.string,
      nombre: PropTypes.string,
      activo: PropTypes.bool.isRequired,
      messages: PropTypes.arrayOf(
        PropTypes.shape({
          sender: PropTypes.string.isRequired,
          message: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onChatSelect: PropTypes.func.isRequired,
  selectedChat: PropTypes.object,
  onOpenMetrics: PropTypes.func.isRequired,
  onShowClientStatus: PropTypes.func.isRequired,
  onOpenSuggestions: PropTypes.func.isRequired,
  onOpenSettings: PropTypes.func.isRequired,
  onOpenMarketing: PropTypes.func.isRequired, // Definir como requerido si siempre se pasa cuando marketing es true
  marketing: PropTypes.bool.isRequired, // Nueva prop para controlar la visibilidad del botón de Marketing
};

export default ChatList;
