// src/components/Calendar/Config.js

import React, { useState, useEffect } from "react";
import CalendarConfigStep from "./CalendarConfigStep";
import CalendarPreviewStep from "./CalendarPreviewStep"; // Importa el nuevo componente
import GoogleCalendarConfigStep from "./GoogleCalendarConfigStep";
import LoadingScreen from "../LoadingScreen";
import axios from "axios";
import { useNavigate } from "react-router-dom";

/**
 * Componente Config
 * Maneja la configuración del calendario y la autorización de Google Calendar.
 */
function Config() {
  const [step, setStep] = useState(1);
  const [calendarConfig, setCalendarConfig] = useState([]);
  const [authCodeProcessedGoogle, setAuthCodeProcessedGoogle] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Obtener datos del localStorage
  const userPhoneID = localStorage.getItem("userPhoneID");
  const token = localStorage.getItem("jwtToken");
  const numCalendars = parseInt(localStorage.getItem("numCalendars"), 10);
  const navigate = useNavigate();

  // Variables de entorno
  const apiGatewayUrl = process.env.REACT_APP_API_GATEWAY_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const googleRedirectUri =
    process.env.REACT_APP_GOOGLE_REDIRECT_URI || "http://localhost:3000/config";

  console.log("API Gateway URL:", apiGatewayUrl);
  console.log("Google Client ID:", googleClientId);
  console.log("Google Redirect URI:", googleRedirectUri);

  useEffect(() => {
    // Verificar si userPhoneID está presente
    if (!userPhoneID) {
      console.error("userPhoneID falta en localStorage.");
      alert(
        "Error: Identificador de usuario ausente. Por favor, inicia sesión nuevamente."
      );
      navigate("/login"); // Redirigir al usuario a la página de inicio de sesión
      return;
    }

    const savedCalendarConfig = localStorage.getItem("calendarConfig");
    if (savedCalendarConfig) {
      setCalendarConfig(JSON.parse(savedCalendarConfig));
      console.log(
        "Configuración de calendarios cargada desde localStorage:",
        JSON.parse(savedCalendarConfig)
      );
    } else {
      const initialCalendarConfig = Array(numCalendars).fill({
        name: "",
        slotDuration: 30,
        availableDays: {},
      });
      setCalendarConfig(initialCalendarConfig);
      console.log(
        "Configuración de calendarios inicializada:",
        initialCalendarConfig
      );
    }
  }, [numCalendars, userPhoneID, navigate]);

  /**
   * Maneja el siguiente paso después de configurar los calendarios.
   * @param {Array} calendars - Configuraciones de calendarios.
   */
  const handleCalendarConfigNext = (calendars) => {
    console.log("Calendarios recibidos desde CalendarConfigStep:", calendars);
    setCalendarConfig(calendars);
    localStorage.setItem("calendarConfig", JSON.stringify(calendars));
    setStep(2); // Avanza al paso de previsualización
    console.log("Avanzado al paso 2: CalendarPreviewStep");
  };

  /**
   * Maneja el código de autorización de Google.
   * @param {string} authCode - Código de autorización de Google.
   */
  const handleGoogleAuthCode = async (authCode) => {
    if (authCodeProcessedGoogle) {
      console.log("Código de autorización de Google ya procesado.");
      return;
    }

    setAuthCodeProcessedGoogle(true);
    setStep(4); // Avanza al paso de envío de configuración inmediatamente
    console.log("Avanzado al paso 4: Enviando configuración");
    console.log("Procesando código de autorización de Google:", authCode);
    try {
      const response = await axios.get(
        `${apiGatewayUrl}/handle-google-auth?code=${authCode}&userPhoneID=${userPhoneID}`
      );
      const { message } = response.data;
      console.log("Respuesta de autorización de Google:", message);
      // No es necesario almacenar tokens en el frontend

      // Limpia la URL después de procesar el código
      window.history.replaceState({}, document.title, "/config");
      console.log("URL limpiada después de autorización de Google.");
    } catch (error) {
      console.error("Error al obtener tokens de Google:", error);
      setStep(2); // Mantiene en el paso de previsualización si hay error
      console.log("Regresado al paso 2: CalendarPreviewStep debido a un error");
    }
  };

  /**
   * Maneja el envío de la configuración completa al backend.
   */
  const handleSubmitConfig = async () => {
    setIsLoading(true); // Inicia la pantalla de carga
    console.log("Enviando configuración completa:", {
      userPhoneID,
      calendarConfig,
      // Los tokens ya están almacenados en el backend
    });
    const fullConfig = {
      userPhoneID,
      calendarConfig,
    };

    try {
      const response = await axios.post(`${apiGatewayUrl}/config`, fullConfig, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Configuración guardada:", response.data);
      if (response.status === 200) {
        setSuccess(true);
        localStorage.removeItem("calendarConfig"); // Limpia localStorage después de guardar
        navigate("/app");
        console.log("Redirigiendo a /app");
      }
    } catch (error) {
      console.error("Error al guardar la configuración:", error);
    } finally {
      setIsLoading(false); // Finaliza la pantalla de carga
      console.log("Envío de configuración completado");
    }
  };

  /**
   * Inicia el flujo de autorización de Google.
   */
  const handleGoogleAuth = () => {
    console.log("Iniciando el flujo de OAuth de Google");
    localStorage.setItem("authProvider", "google"); // Marca que estamos autorizando Google
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(
      "https://www.googleapis.com/auth/calendar"
    )}&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${encodeURIComponent(
      googleRedirectUri
    )}&client_id=${googleClientId}&prompt=consent`;
    console.log("URL de Autorización de Google:", authUrl);
    window.location.assign(authUrl); // Usar window.location.assign para redirigir
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const authCode = query.get("code");
    const authProvider = localStorage.getItem("authProvider"); // Obtener el proveedor actual

    console.log("Parámetros de la URL:", { authCode });
    console.log("Proveedor de Autorización:", authProvider);

    if (authCode) {
      if (authProvider === "google" && !authCodeProcessedGoogle) {
        console.log("Autorización detectada para Google");
        handleGoogleAuthCode(authCode);
        localStorage.removeItem("authProvider"); // Limpia el proveedor después de procesar
      } else {
        console.warn(
          "Código de autorización recibido pero no coincide con ningún paso activo."
        );
      }
    }
  }, [authCodeProcessedGoogle, step]);

  useEffect(() => {
    if (step === 4) {
      console.log("Paso 4 alcanzado: Enviando configuración");
      handleSubmitConfig();
    }
  }, [step]);

  /**
   * Redirige al usuario a la aplicación principal.
   */
  const handleRedirectToApp = () => {
    console.log("Redirigiendo a /app");
    window.location.assign("/app");
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto w-full">
        <div className="absolute inset-0 bg-gradient-to-r from-bluePalette-light to-bluePalette-darker shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 max-h-[90vh] overflow-hidden scrollbar-thin scrollbar-thumb-bluePalette-dark scrollbar-track-bluePalette-lightest">
          {isLoading ? (
            <LoadingScreen />
          ) : success ? (
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-6">
                ¡Configuración completada!
              </h2>
              <button
                onClick={handleRedirectToApp}
                className="px-4 py-2 bg-bluePalette-dark text-white rounded hover:bg-bluePalette-darker"
              >
                Ir a la aplicación
              </button>
            </div>
          ) : (
            <>
              {step === 1 && (
                <CalendarConfigStep
                  onNext={handleCalendarConfigNext}
                  calendars={calendarConfig}
                  numCalendars={numCalendars}
                />
              )}
              {step === 2 && (
                <CalendarPreviewStep
                  calendars={calendarConfig}
                  onConfirm={() => setStep(3)} // Avanza al paso de autorización de Google
                  onEdit={() => setStep(1)} // Vuelve al paso de configuración si se quiere editar
                />
              )}
              {step === 3 && (
                <GoogleCalendarConfigStep onGoogleAuth={handleGoogleAuth} /> // Paso de autorización de Google
              )}
              {step === 4 && <LoadingScreen />}{" "}
              {/* Paso de envío de configuración */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Config;
